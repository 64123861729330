require('./bootstrap');

// copy/paste functionality
if (document.body.querySelector('.button-container-old')) {

    // get the container with the copy/paste buttons
    let container = document.body.querySelector('.button-container');

    // assign the children
    var children = container.children;

    // loop over children
    for (i = 0; i < children.length; i++) {

        // if a button contains class copy
        if (children[i].classList.contains('copy')) {

            // if we have a stored value
            if (localStorage.getItem("descriptions") != null) {
                // add the unselecred class
                children[i].classList.add('btn-light-blue');
            }

            // listen for a click on copy
            children[i].addEventListener('click', function (event, target) {

                if (event) {
                    // stop button submit
                    event.preventDefault();
                    event.stopPropagation();

                    // store descriptions in an array
                    let descriptions = [
                        container.nextElementSibling.querySelector('input[name="description-1"]').value,
                        container.nextElementSibling.querySelector('input[name="description-2"]').value,
                        container.nextElementSibling.querySelector('input[name="description-3"]').value,
                        container.nextElementSibling.querySelector('input[name="description-4"]').value,
                    ];

                    // json encode the descriptions and store them in local storage
                    localStorage.setItem("descriptions", JSON.stringify(descriptions));

                }
            });

        }

        // if a button contains class copy
        if (children[i].classList.contains('paste')) {

            // if we have a stored value
            if (localStorage.getItem("descriptions") != null) {
                // remove unselected class
                children[i].classList.remove('btn-light-blue');
            }

            // listen for a click on paste
            children[i].addEventListener('click', function (event, target) {


                if (event) {
                    // stop button submit
                    event.preventDefault();
                    event.stopPropagation();

                    // if we have a stored value
                    if (localStorage.getItem("descriptions") != null) {

                        // get the descriptions from local storage and decode
                        let descriptions = JSON.parse(localStorage.getItem("descriptions"));

                        // set values of descriptions
                        container.nextElementSibling.querySelector('input[name="description-1"]').value = descriptions[0];
                        container.nextElementSibling.querySelector('input[name="description-2"]').value = descriptions[1];
                        container.nextElementSibling.querySelector('input[name="description-3"]').value = descriptions[2];
                        container.nextElementSibling.querySelector('input[name="description-4"]').value = descriptions[3];

                    }
                }
            });
        }


        console.log(children[i]);


    }

    // console.log(container.children);


    // inputClick = function(event, target){
    //
    //     console.log(event);
    // }

}

$(document).ready(function() {
    $('[data-action="numeric-increase"]').click(function (event) {
        event.preventDefault();
        let target = '#' + $(this).data('target');

        let value = parseInt($(target).val());
        $(target).val(value + 1);
    });

    $('[data-action="numeric-decrease"]').click(function (event) {
        event.preventDefault();
        let target = '#' + $(this).data('target');

        let value = parseInt($(target).val());
        if (value > 1) {
            $(target).val(value - 1);
        }
    });

    $('#upload').change(function(){
        let filePath = $(this).val();

        if (filePath !== '') {
            $('#fileName').text(filePath.split('\\').pop());
        }

        $('.import-bulk-upload-selected').removeClass('hide');
        $('.import-bulk-upload-empty').addClass('hide');
    });

    $('#clearFile').click(function () {
        $('#upload').val('');

        $('#fileName').text('');
        $('.import-bulk-upload-selected').addClass('hide');
        $('.import-bulk-upload-empty').removeClass('hide');
    });
});
